<template>
  <div>
    <div class="total-info">
      <div class="total-item blue-item">
        <span>今日交易额：</span>
        <span>{{ statisticsInfo.amount || 0 }}</span>
      </div>
      <div class="total-item green-item">
        <span>今日交易笔数：</span>
        <span>{{ statisticsInfo.sum || 0 }}</span>
      </div>
      <div class="total-item green-item">
        <span>今日服务人数：</span>
        <span>{{ statisticsInfo.userCount || 0 }}</span>
      </div>
      <div class="total-item blue-item">
        <span>今日客单价：</span>
        <span>{{ statisticsInfo.price || 0 }}</span>
      </div>
    </div>
    <div class="table-page-title">
      <div class="fl">
        <el-form :model="searchForm" class="search-form" :inline="true">
          <el-form-item label="" prop="deviceId">
            <el-input class="small-input" v-model="searchForm.deviceId" placeholder="设备id"></el-input>
          </el-form-item>
          <el-form-item label="" prop="name">
            <el-input class="small-input" v-model="searchForm.name" placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item label="" prop="telephone">
            <el-input class="small-input" v-model="searchForm.telephone" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item label="" prop="orderNo">
            <el-input class="small-input" v-model="searchForm.orderNo" placeholder="订单号"></el-input>
          </el-form-item>
          <el-form-item label="" prop="department">
            <el-input class="small-input" v-model="searchForm.department" placeholder="单位名称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="status">
            <el-select clearable class="small-input" v-model="searchForm.status" placeholder="订单状态">
              <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="" prop="deviceType">
            <el-select clearable class="small-input" v-model="searchForm.deviceType" placeholder="设备类型">
              <el-option
                  v-for="item in deviceTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>-->
        </el-form>
      </div>
      <div class="fl search-form-btn">
        <el-button class="fl" size="small" type="primary" @click="search()">搜 索</el-button>
        <el-button class="fl" size="small" @click="reset()">重 置</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column
            label="订单号"
            prop="orderNo"
            fixed="left"
            align="center"
            header-align="center"
            width="160"
        >
          <template slot-scope="scope">
            <span class="link_word" @click="goDetail(scope.row.orderNo)" style="overflow:hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;       white-space: normal;">{{ scope.row.orderNo || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="下单时间"
            prop="createTime"
            width="180"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
                label="取出时间"
                prop="pickTime"
                width="180"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="归还时间"
            prop="returnTime"
            width="180"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备类型"
            prop="deviceType"
            width="80"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.deviceType ===0?"陪护床":scope.row.deviceType ===1 ? "轮椅": '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="设备id"
            prop="deviceId"
            width="180"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <span class="link_word" @click="searchByDeviceId(scope.row.deviceId)">{{ scope.row.deviceId || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="查看设备"
            prop="imei"
            width="120"
            align="center"
            header-align="center"
            show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span class="link_word" @click="goDeviceDetail(scope.row.deviceId)">{{ scope.row.imei || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="单位"
            align="center"
            header-align="center"
            show-overflow-tooltip="true"
            width="180"
            prop="department">
          <template slot-scope="scope">
            <span class="link_word" @click="searchByCompany(scope.row.department)">{{ scope.row.department || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="楼层"
            align="center"
            header-align="center"
            show-overflow-tooltip="true"
            width="180"
            prop="floor">
          <template slot-scope="scope">
            <span class="link_word">{{ scope.row.floor || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="用户名"
            prop="userName"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <span class="link_word" @click="searchByUserName(scope.row.userName)">{{ scope.row.userName || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="手机号"
            prop="telephone"
            width="120"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="订单状态"
            prop="status"
            align="center"
            header-align="center"
            width="120"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
            {{ row.status === 0 ? '下单待支付' : row.status === 1 ? '已支付待取货' : row.status === 2 ? '租赁中' : row.status === 3 ? '已归还待支付' : row.status === 4 ? '归还结算完成' : row.status === 5 ? '订单关闭' : row.status === 6 ?"订单异常" : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="押金金额"
            prop="depositAmount"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="租金金额"
            prop="rentalAmount"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="押金状态"
            prop="depositStatus"
            align="center"
            header-align="center"
            width="120"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
            {{ row.depositStatus === 0 ? '待支付押金' : row.depositStatus === 1 ? '已支付押金' : row.depositStatus === 2 ? '待退还押金' : row.depositStatus === 3 ? '已返还押金' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="租金状态"
            prop="rentalStatus"
            align="center"
            header-align="center"
            width="120"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
            {{ row.rentalStatus === 0 ? '未开始计费' : row.rentalStatus === 1 ? '开始租金计费' : row.rentalStatus === 2 ? '待支付租金' : row.rentalStatus === 3 ? '已支付租金' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="货道编号"
            prop="wayNo"
            align="center"
            header-align="center"
        >
        </el-table-column>
        <el-table-column
            label="货道状态"
            prop="wayNoStatus"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
          {{ row.wayNoStatus === 0 ? '可租赁' : row.wayNoStatus === 1 ? '已分配' : row.wayNoStatus === 2 ? '租赁中' : row.wayNoStatus === 3 ? '归还中' : row.wayNoStatus === 4 ? '消毒中' : row.wayNoStatus === 5 ? '冷却中' : row.wayNoStatus === 6 ? '不可用' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="锁状态"
            prop="doorStatus"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
            {{row.doorStatus === 1 ? '锁开' : row.doorStatus === 0 ? '锁关' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="货状态"
            prop="hasGoods"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
            {{row.hasGoods === 0 ? '无床' : row.hasGoods === 1 ? '有床' : row.hasGoods === 2 ? 'RFID异常' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="成功退款"
            width="100"
            prop="refund"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
           {{ row.refund == '0' ? '无' : row.refund == null ? '无' : '有' }}
          </template>
        </el-table-column>
        <el-table-column
            label="订单异常"
            prop="abnormal"
            width="100"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
            {{ row.abnormal ? '有' : '无' }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
import {setMenuList} from "@/assets/js/common";
export default {
  name: "OrderList",
  mixins:[tableUse],
  computed:{
    deviceId(){
      return this.$route.params.deviceId
    },
    department(){
      return this.$route.params.department
    },
    name(){
      return this.$route.params.name
    }
  },
  data(){
    return {
      searchForm:{
        deviceId:'',
        name:'',
        telephone:'',
        orderNo:'',
        department:'',
        status:'',
        deviceType: 0
      },
      statusList:[
        {
          value:0,
          label:'下单待支付'
        },
        {
          value:1,
          label:'已支付待取货'
        },
        {
          value:2,
          label:'租赁中'
        },
        {
          value:3,
          label:'已归还待支付'
        },
        // {
        //   value:4,
        //   label:'已确认待结算'
        // },
        {
          value:4,
          label:'归还结算完成'
        },
        {
          value:5,
          label:'订单关闭'
        },
        {
          value:6,
          label:'订单异常'
        }
      ],
      deviceTypes:[
        {
          value:0,
          label:'陪护床'
        },
        {
          value:1,
          label:'轮椅'
        }
      ],
      tableData:[
      ],
      statisticsInfo:{}
    }
  },
  methods:{
    reset(){
      this.searchForm = {
        deviceId:'',
        name:'',
        telephone:'',
        orderNo:'',
        department:'',
        status:'',
        deviceType:''
      }
      this.search();
    },
    goDetail(orderNo){
      setMenuList({
        path:'/orderDetail',
        name:'订单详情'
      })
      this.$router.push({name:'OrderDetail',query:{orderNo}})
    },
    goDeviceDetail(deviceId){
      this.$router.push({name:'DeviceDetail',query:{id:deviceId}})
    },
    searchByDeviceId(deviceId){
      this.searchForm = {
        deviceId:'',
        name:'',
        telephone:'',
        orderNo:'',
        department:'',
        status:''
      }
      this.searchForm.deviceId = deviceId;
      this.search();
    },
    searchByCompany(department){
      this.searchForm = {
        deviceId:'',
        name:'',
        telephone:'',
        orderNo:'',
        department:'',
        status:''
      }
      this.searchForm.department = department;
      this.search();
    },
    searchByUserName(userName){
      this.searchForm = {
        deviceId:'',
        name:'',
        telephone:'',
        orderNo:'',
        department:'',
        status:''
      }
      this.searchForm.name = userName;
      this.search();
    },
    getStatisticsInfo(){
      this.$axios.get("/sys-api/order-api/getQuantity",{deviceType:0},(res) => {
        if(res.code === '100'){
          this.statisticsInfo = res.data;
        }
      })
    },
    getTableData(){
      this.tableLoading = true;
      let formData = {
        pageNum:this.pageNum,
        pageSize: this.pageSize
      }
      if(this.searchForm.deviceId) formData.deviceId = this.searchForm.deviceId;
      if(this.searchForm.name) formData.name = this.searchForm.name;
      if(this.searchForm.telephone) formData.telephone = this.searchForm.telephone;
      if(this.searchForm.orderNo) formData.orderNo = this.searchForm.orderNo;
      if(this.searchForm.department) formData.department = this.searchForm.department;
      if(this.searchForm.status || this.searchForm.status === 0) formData.status = this.searchForm.status;
      if(this.searchForm.deviceType || this.searchForm.deviceType === 0) formData.deviceType = this.searchForm.deviceType
      this.$axios.post("/sys-api/order-api/orderPage",formData,(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      })
    }
  },
  mounted() {
    if(this.deviceId) this.searchForm.deviceId = this.deviceId;
    if(this.department) this.searchForm.department = this.department;
    if(this.name) this.searchForm.name = this.name;
    this.getStatisticsInfo();
    this.getTableData();
  }
}
</script>

<style scoped lang="scss">
</style>
